.mc-benefits-container {
  position: relative;

  &__head {
    text-align: center;
    width: 342px;
    margin: 20px auto;

    @media only screen and (min-width: 992px) {
      width: 100%;
      margin-top: 90px;
    }

    div {
      &:nth-child(1) {
        color: var(--Text-Text-Title, #07254A);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &:nth-child(2) {
        color: var(--Text-Accent, #005EB8);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      @media only screen and (min-width: 992px) {
        display: inline-block;
        width: auto;
        margin: 0 3px;
      }
    }
  }

  &__slide {
    margin-left: 14px;

    .mc-slider-rotator {
      .slick-list {
        width: 85%;
        overflow: visible;

        @media only screen and (min-width: 992px) {
          margin-left: auto;
          margin-right: auto;
        }

        .slick-track {
          .slick-slide {
            .mc-slider-rotator__slide  {
              .mc-cardbox-container {
                h2 {
                  height: 90px;
                  strong {
                    display: none !important;
                  }
                  div {
                    color: var(--Text-Paragraph, #6D6D6D);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-align: left;

                    span {
                      color: var(--Text-Accent, #005EB8);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}