.mc-custom-modal {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 999;
  visibility: hidden;
  opacity: 0;

  &__overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 1;
    background: rgba(11, 59, 111, 0.9);
    mix-blend-mode: multiply;
  }

  &__center {
    width: 430px;
    height: 100%;
    margin: 0 auto;
    max-width: 90%;
    z-index: 1;

    &--window {
      position: relative;
      border-radius: 25px;
      background: #EAF3F9;
      visibility: hidden;
      opacity: 0;
      transform: scale(0);
      box-sizing: border-box;
      padding: 36px 17px;
      box-shadow: 0px 2px 14px 0px rgba(0,0,0,0.1);
      -webkit-box-shadow: 0px 2px 14px 0px rgba(0,0,0,0.1);
      -moz-box-shadow: 0px 2px 14px 0px rgba(0,0,0,0.1);
      max-height: 550px;
      overflow: auto;

      &__image {
        height: 80px;
        width: 90px;
        margin: 0 auto;
        display: block;
        margin-top: 20px;
        box-sizing: border-box;
        background-position: center center !important;
        background-repeat: no-repeat !important;
        background-size: 80% !important;
      }
      &__title {
        text-align: center;
        margin: 24px 0px;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        color: $colorText;
      }
      &__desc {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        margin: 18px 0;
      }
      &__actions {
        margin: 20px 0px;

        @media only screen and (min-width: 992px) {
          margin-top: 30px;
        }

        .mc-button-container {
          margin: 10px 0px;
        }
      }
      &__terms {
        text-align: center;
        margin-bottom: 16px;
        a {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          text-decoration-line: underline;
          color: $colorText;
        }
      }
    }
  }

  &__close {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;

    svg {
      path {
        fill: $primaryColorDark;
        stroke: $primaryColorDark;
      }
    }
    
    &:hover {
      opacity: 0.6;
    }
  }

  &__opened {
    visibility: visible;
    opacity: 1;
    transform: scale(1);

    .mc-custom-modal__center--window {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }

  &.is-redemtion {
    .mc-custom-modal__center {
      width: 320px;

      @media only screen and (min-width: 992px) {
        width: 600px;
      }
    }
    .mc-custom-modal__center--window {
      text-align: center;
      .mc-custom-modal__title {
        color: var(--Text-Accent, #005EB8);
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        width: 190px;
        margin: 0 auto;
        margin-top: 30px;

        @media only screen and (min-width: 992px) {
          width: 100%;
        }
      }
      .mc-custom-modal__center--window__image {
        display: inline-block;
        vertical-align: middle;
        width: 100px;
        height: 100px;
        margin-left: 10px;

        border-radius: 12px;
        border: 1px solid var(--Outline-Low, #E0EEFE);
        background: var(--Background-Background-Lowest, #FFF);
        box-shadow: 0px 4px 4px 0px rgba(97, 103, 255, 0.25);
        margin-bottom: 20px;
      }
      .mc-custom-modal__center--window__title {
        display: inline-block;
        vertical-align: middle;
        width: 140px;

        color: var(--Button-Default, #005EB8);
        text-align: left !important;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-left: 18px;
        margin-top: 36px;

        span {
          display: block;
          color: var(--Text-Accent, #005EB8);
          text-align: left;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
      .mc-custom-modal__center--window__actions {
        margin-top: 12px;
        max-width: 280px;
        margin-left: auto;
        margin-right: auto;

        button {
          border-radius: 8px;
          background: var(--Button-Default, #005EB8);
        }

        &.extra-modal {
          .mc-button-container__secondary {
            button {
              color: var(--Button-Default, #005EB8);
              text-align: center;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              text-decoration-line: underline;

              background-color: transparent;
              border: 0px;
            }
          }
        }
      }
      .mc-custom-modal__center--window__terms {
        display: none;
      }
      .mc-custom-modal__center--window__extra {
        position: relative;

        div {
          // &:nth-child(1) {
          //   border-radius: 12px;
          //   border: 1px solid var(--Outline-Low, #E0EEFE);
          //   background: var(--Background-Background-Lowest, #FFF);
          //   box-shadow: 0px 64px 18px 0px rgba(124, 192, 253, 0.00), 0px 41px 16px 0px rgba(124, 192, 253, 0.01), 0px 23px 14px 0px rgba(124, 192, 253, 0.05), 0px 10px 10px 0px rgba(124, 192, 253, 0.09), 0px 3px 6px 0px rgba(124, 192, 253, 0.10);                
          
          //   color: var(--Text-Accent, #005EB8);
          //   font-size: 14px;
          //   font-style: normal;
          //   font-weight: 400;
          //   line-height: normal;
          //   padding: 14px;
          //   padding-left: 45px;
          //   text-align: left;
          //   margin-bottom: 26px;

          //   svg {
          //     position: absolute;
          //     left: 8px;
          //     top: 22px;

          //     @media only screen and (min-width: 992px) {
          //       top: 7px;
          //     }
          //   }
          // }

          &:nth-child(1) {
            border-radius: 8px;
            background: var(--Background-Background-Medium, #B9DCFE);
            box-shadow: 0px 209px 58px 0px rgba(51, 135, 209, 0.00), 0px 134px 53px 0px rgba(51, 135, 209, 0.02), 0px 75px 45px 0px rgba(51, 135, 209, 0.08), 0px 33px 33px 0px rgba(51, 135, 209, 0.13), 0px 8px 18px 0px rgba(51, 135, 209, 0.15);
          
            color: var(--Text-Accent, #005EB8);
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding: 16px 20px;
            margin-bottom: 18px;
          }

          &:nth-child(3) {
            color: var(--Text-Paragraph, #6D6D6D);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 26px;
          }
        }

        .anchor {
          color: var(--Text-Accent, #005EB8) !important;
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 700 !important;
          line-height: normal !important;
          text-align: left !important;


          border-bottom: 1px solid #005EB8;
          text-align: left;
          display: block;
          text-decoration: none;
          padding: 12px;
          margin-bottom: 20px;
          cursor: pointer;
          position: relative;

          &.active {
            svg {
              transform: rotate(0deg) !important;
            }
          }

          svg {
            position: absolute;
            right: 10px;
            top: 15px;
            z-index: 0;
            transform: rotate(180deg);
          }
        }

        & > p {
          color: var(--Text-Paragraph, #6D6D6D);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .termsD {
          div {
            background-color: transparent;
            border: 0px;
            padding: 0px 8px;
            box-shadow: none;

            color: var(--Text-Paragraph, #6D6D6D);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: left !important;
          }
        }
      }
      .mc-custom-modal__center--window__disclaimer {
        height: 250px;
        padding-top: 20px;

        div {
          &:nth-of-type(1) {
            margin: 0 auto;
            margin-top: -170px;
            position: relative;
            z-index: 1;
            color: var(--azul-2-pacifico, #005EB8);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            width: 244px;
            margin-bottom: 30px;
          }
          &:nth-of-type(2) {
            margin: 0 auto;
            width: 244px;
            color: var(--Text-Text-Title, #07254A);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }

  &.is-post-redemtion {
    .mc-custom-modal__center--window__title {
      max-height: 130px;
      z-index: 1;
      position: relative;
      img {
        position: relative;
        width: 90%;
        height: auto;
        margin-left: 0px;
        display: block ;
      }
    }
    .mc-custom-modal__center--window__desc {
      position: relative;
      border-radius: 12px;
      border: 1px solid var(--Outline-Low, #E0EEFE);
      background: var(--Background-Background-Lowest, #FFF);
      box-shadow: 0px 64px 18px 0px rgba(124, 192, 253, 0.00), 0px 41px 16px 0px rgba(124, 192, 253, 0.01), 0px 23px 14px 0px rgba(124, 192, 253, 0.05), 0px 10px 10px 0px rgba(124, 192, 253, 0.09), 0px 3px 6px 0px rgba(124, 192, 253, 0.10);

      div {
        padding-left: 50px;
        color: var(--Text-Accent, #005EB8);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        height: 60px;
        padding-top: 16px;
        padding-right: 10px;
      }

      svg {
        position: absolute;
        left: 10px;
        top: 20px;
      }
    }
    .mc-custom-modal__center--window__pdf {
      text-align: center;
      position: relative;
      display: block;
      z-index: 2;
      height: 200px;
      margin-top: -50px;
      overflow: hidden;
      cursor: pointer;

      #anchorID {
        display: none !important;
      }

      @media only screen and (min-width: 992px) {
        margin-top: -35px;
      }
      // box-shadow: -1px 0px 15px 0px rgba(0,0,0,0.2) inset;
      // -webkit-box-shadow: -1px 0px 15px 0px rgba(0,0,0,0.2) inset;
      // -moz-box-shadow: -1px 0px 15px 0px rgba(0,0,0,0.2) inset;
            
      &--canvas {
        overflow: hidden;
        transform: scale(0.48);
        position: relative;

        &:after {
          content: '';
          position: absolute;
          left: 0px;
          top: 0px;
          right: 0px;
          bottom: 0px;
          z-index: 4;
          background-color: transparent;
        }

        .rpv-core__inner-pages.rpv-core__inner-pages--vertical {
          overflow: hidden !important;
        }
      }
    }
    .mc-button-container {
      button {
        background-color: #005EB8;
      }
    }
  }

  &.pushModal{
    .mc-custom-modal__center{
      max-width: 344px;
    }
    .mc-custom-modal__center--window__image{
      width: 100px;
      height: 100px;
      background-size: 100% !important;
    }
    .mc-custom-modal__center--window__title{
      color: var(--azul-2-pacifico, #005EB8);
      font-family: 'DIN Pro';
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
      margin-bottom: 16px;
    }
    .mc-custom-modal__center--window__desc{
      color: var(--Text-Text-Title, #07254A);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .specialAward{
      color: var(--Text-Text-Title, #07254A);
      text-align: center;      
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      strong{
        color: var(--Text-Text-Title, #07254A);
        font-family: 'DIN Pro';
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }

  &.global{
    .mc-custom-modal__center{
      max-width: 344px;
    }
    .mc-custom-modal__center--window__image{
      width: 100px;
      height: 100px;
      background-size: 100% !important;
    }
    .mc-custom-modal__center--window__title{
      color: var(--azul-2-pacifico, #005EB8);
      font-family: 'DIN Pro';
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
      margin-bottom: 16px;
    }
    .mc-custom-modal__center--window__desc{
      color: var(--Text-Text-Title, #07254A);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      p{
        margin: 16px 0;
        color: var(--Text-Accent, #005EB8);
        text-align: center;        
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    .mc-custom-modal__center--window__actions{
      .mc-button-container{
        width: 105px;
        margin: 0 auto;
      }
    }

  }
}