.mc-login {
  position: relative;
  height: auto;
  padding: 26px 0px;

  h2 {
    color: var(--Text-Inverted, #F0F7FF);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    margin-top: 0px;
  }
  
  &__form {
    position: relative;
    display: block;
    margin: 0 auto;
    padding-bottom: 8px;
    box-sizing: border-box;
    border-radius: 4px;
    max-width: 400px;

    &--fields {
      position: relative;
      margin-bottom: 22px;
    }

    &--overlay {
      position: absolute;
      left: 0px;
      top: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 5;
      background-color: transparent;
      opacity: 0.5;
      cursor: no-drop;
    }
  }
}

.mc-input-container {

  &__box {
    position: relative;
    margin-bottom: 22px;

    label {
      color: var(--Text-Inverted, #F0F7FF);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: block;
      margin-bottom: 12px;
    }

    input, textarea, select{
      width: 100%;
      box-sizing: border-box;
      border: 0px;
      outline: 0px;
      height: 50px;
      position: relative;
      padding: 0 12px;
      min-width: 100%;
      max-width: 100%;

      border-radius: 8px;
      border: 1px solid var(--Base---CTA, #005EB8);
      background: var(--Background-Background-Low, #F0F7FF);

      color: var(--Text-Placeholder, #707070);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &[type="date"] {
        text-transform: uppercase;
      }
    }

    textarea {
      padding-top: 5px;
      padding-bottom: 5px;
      resize: none;
      height: 130px;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
      background: none;
      background-size: 0px;
    }

    input::placeholder,
    input:-ms-input-placeholder,
    input::-ms-input-placeholder { 
      opacity: 1 !important;
    }

    .mc-form-label {
      color: var(--Text-Inverted, #F0F7FF);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &.terms  {
      display: block;
      position: relative;
      padding-left: 30px;
      margin-bottom: 0px !important;
      cursor: pointer;
      font-size: 16px !important;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-weight: normal !important;
      line-height: 8px;

      width: 256px;
      margin: 0 auto;

      a {
        color: var(--Text-Inverted, #F0F7FF);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
        margin-left: 4px;
        position: relative;
        z-index: 4;
      }
      input {
        position: absolute;
        cursor: pointer;
        height: 100%;
        width: 100%;
        left: 0px;
        top: 0px;
        z-index: 2;
        opacity: 0;
      }
      label {
        display: inline;
        color: var(--Text-Inverted, #F0F7FF);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .mc-input-checkbox__checkmark {
        position: absolute;
        top: -3px;
        left: 0;
        height: 18px;
        width: 18px;
        background-color: transparent;
        border-radius: 3px;
        border: 2px solid #ffffff;
        transform: scale(0.9);
      }
      .mc-input-error {
        margin-top: 14px;
        line-height: 16px !important;
      }
    }

    &.terms:hover input ~ .mc-input-checkbox__checkmark {
      background-color: rgba(255,255,255,0.3);
    }
    &.terms input:checked ~ .mc-input-checkbox__checkmark {
      background-color: $primaryColor;
      border: 2px solid $primaryColor !important;
    }
    
    .mc-input-checkbox__checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    &.terms input:checked ~ .mc-input-checkbox__checkmark:after {
      display: block;
    }
    
    &.terms .mc-input-checkbox__checkmark:after {
      left: 6px;
      top: 2px;
      width: 4px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg) scale(0.8);
      -ms-transform: rotate(45deg) scale(0.8);
      transform: rotate(45deg) scale(0.8);
    }

    .mc-input-by-character {
      input {
        display: inline-block;
        vertical-align: middle;
        width: 20%;
        min-width: 20px;
        margin: 0 1%;

        &:first-child {
          margin-left: 0px;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}

.mc-input-separated-container {
  input {
    min-width: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin: 0 1%;
    color: #000;
  }
}

.mc-input-container__box--error {
  input {
    border: 1px solid red !important;
  }
}

.mc-input-error {
  color: red;
  background-color: rgb(255, 244, 244);
  border: 1px solid red;
  font-size: 12px;
  display: block;
  padding: 8px 14px;
  margin-top: 8px;
  border-radius: 4px;
}