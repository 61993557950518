@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

html, body {
  margin: 0px;
  padding: 0px;

  * {
    font-family: 'DIN Pro', sans-serif;
  }
}

.full-height {
  height: 100%;
}

.mc-tag-card {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  background-color: $primaryColor;
  color: #fff;
  font-size: 10px;
  padding: 2px 5px;
  padding-bottom: 3px;

  &.position-left {
    right: auto;
    left: 10px;
  }
}

.back-button {
  margin-top: 30px;
  cursor: pointer;
  div {
    display: inline-block;
    vertical-align: middle;

    svg {
      margin-right: 10px;
      position: relative;
      display: block;
    }
    span {
      color: var(--Button-Default, #005EB8);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-decoration-line: underline;
    }
  }
}