.mc-page {
  position: relative;
  padding: 0 0 70px;

  &.dashboard {
    background-color: rgb(240, 240, 240);
    padding-top: 25px;

    .mc-app-container {
      width: 1110px;
      padding: 0 15px;
    }
  }

  &.terms,
  &.faqs {
    h1 {
      @media only screen and (max-width: 992px) {
        text-align: center;
      }
    }
  }

  .mc-static-content {
    margin: 30px 0;

    @media only screen and (min-width: 992px) {
      margin: 50px 0;
    }
  
  }
}

.mc-section-login-slider {
  margin: 30px 0px;

  h3 {
    color: var(--Text-Text-Title, #07254A);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 108.333% */
    width: 290px;
    margin: 24px auto;
  }
}

.mc-section-login-form {
  border-radius: var(--xl, 24px);
  background: var(--Gradient, linear-gradient(180deg, #0575E6 0%, #023B82 132.61%));
  @media only screen and (max-width: 992px) {
    margin-left: 12px;
    margin-right: 12px;
  }

  .mc-button-container {
    button {
      background: #fff;
      color: var(--Text-Text-Title, #07254A);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      img {
        filter: invert(100%);
        -webkit-filter: invert(100%);
        -moz-filter: invert(100%);
      }
    }
  }
}

.mc-section-login-banner {
  position: relative;
  background: var(--Background-Background-Low, #F0F7FF);
  padding: 40px 16px;
  margin: 40px 0px;
  margin-top: 50px;

  &__image {
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    &--text  {
      position: absolute;
      z-index: 2;
      left: 12px;
      right: 12px;
      bottom: 18px;
      text-align: center;
      padding: 8px 0px;
      border-radius: 16px;
      opacity: 0.9;
      background: var(--Gradient, linear-gradient(180deg, #0575E6 0%, #023B82 132.61%));
      box-shadow: 0px 103px 29px 0px rgba(0, 0, 0, 0.00), 0px 66px 26px 0px rgba(0, 0, 0, 0.03), 0px 37px 22px 0px rgba(0, 0, 0, 0.10), 0px 16px 16px 0px rgba(0, 0, 0, 0.17), 0px 4px 9px 0px rgba(0, 0, 0, 0.20);
      
      p {
        color: var(--Text-Inverted, #F0F7FF);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 6px 0px;

        strong {
          color: var(--Text-Inverted, #F0F7FF);
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 900;
          line-height: 28px; /* 116.667% */
        }
      }
    }
  }

  &__labels {
    position: relative;
    text-align: center;
    margin-top: 20px;
    padding: 0 16px;

    div {
      color: var(--Text-Text-Title, #07254A);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    p {
      color: var(--Text-Paragraph, #6D6D6D);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

.mc-section-login-slider2 {
  margin: 30px 0px;

  h3 {
    color: var(--Text-Text-Title, #07254A);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 108.333% */
    padding: 0 16px;
  }
}

.mc-section-login-slider,
.mc-section-login-slider2 {
  .mc-slider-rotator {
    .slick-dots {
      text-align: left;
      width: auto;
      left: 16px;
      right: 0px;
      bottom: -15px;

      li {
        width: 8px;
        height: 8px;
        background-color: $primaryColor !important;
        opacity: 0.4;
        &.slick-active {
          width: 20px;
          opacity: 1 !important;
          background-color: $primaryColorDark !important;
        }
      }
    }
  }
}

.mc-header-container-page-login {
  .mc-header-container__menu {
    .mc-header-container__nav {
      display: none !important;
    }
  }
  .mc-header-container__submenu {
    display: none !important;
  }
}

.mc-header-container-page-progreso {
  .mc-header-container__submenu {
    display: none !important;
  }
}

.mc-section-login-group-1 {
  @media only screen and (max-width: 992px) {
    overflow: hidden;
  }
  @media only screen and (min-width: 992px) {
    height: 450px;
    position: relative;
    margin-top: 70px;
    margin-bottom: 50px;

    .mc-section-login-form {
      position: absolute;
      left: -578px;
      right: 0px;
      margin: auto;
      top: 0px;
      bottom: 0px;
      width: 500px;
      z-index: 2;
      border-radius: var(--xl, 24px) !important;
      margin-top: -30px;

      .mc-login {
        width: 404px;
        margin: 0 auto;
        padding-top: 76px;
      }
    }

    .mc-section-login-slider {
      max-width: 90%;
      margin: 0 auto;
      z-index: 2;
      width: 1100px;
      padding: 0 20px;

      h3 {
        text-align: left;
        width: 100%;
        margin-bottom: 12px;
        padding-left: 546px !important;
        margin-top: 50px;
      }

      .mc-slider-rotator {
        position: relative;
        left: 530px;
        padding-right: 60px;

        .slick-dots {
          text-align: center !important;
          right: auto;
          width: 74%;
        }
      }

      .cardBoxStyle-1 {
        h2 {
          display: block;
        }
        h4 {
          display: none;
          color: var(--Text-Text-Title, #07254A);
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin: 14px 0px;
          height: 64px;
          padding: 0 8px;

          span {
            color: var(--Text-Accent, #005EB8);
          }
        }
      }
    }
  }
}

.mc-section-login-group-2 {
  display: none !important;
  @media only screen and (max-width: 992px) {
    overflow: hidden;
  }
  @media only screen and (min-width: 992px) {
    height: 610px;
    position: relative;
    margin-bottom: 50px;

    .mc-slider-rotator {
      .slick-dots {
        bottom: -25px;
      }
    }

    .mc-section-login-banner {
      position: absolute;
      left: 0px;
      top: 0px;
      bottom: 0px;
      width: 580px;
      z-index: 2;
      border-radius: 0px var(--xl, 24px) var(--xl, 24px) 0px;
      background: var(--Background-Background-Low, #F0F7FF);
      margin-top: 0px;
      margin-bottom: 0px;
      padding-left: 40px;
      padding-right: 40px;
      box-sizing: border-box;
      margin-top: -46px;

      .mc-section-login-banner__labels {
        width: 290px;
        margin: 0 auto;
        margin-top: 14px;
      }
    }
    .mc-section-login-slider2 {
      padding-left: 610px;
      margin-top: 96px;

      h3 {
        text-align: left;
        width: 100%;
      }
    }
  }
  @media only screen and (min-width: 1300px) {
    height: 660px;
    .mc-section-login-banner {
      width: 660px;
      margin-top: -76px;
    }
    .mc-section-login-slider2 {
      padding-left: 690px;
      margin-top: 130px;
    }
  }
  @media only screen and (min-width: 1600px) {
    height: 760px;
    .mc-section-login-banner {
      width: 760px;
      margin-top: -76px;
    }
    .mc-section-login-slider2 {
      padding-left: 810px;
      margin-top: 130px;
    }
  }
}

.mc-page {
  &.login {
    h3.h3-desktop {
      display: none;
      color: var(--Text-Text-Title, #07254A);
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px; /* 108.333% */
      margin-top: 50px;
  
      span {
        color: var(--Text-Accent, #005EB8);
      }

      @media only screen and (min-width: 992px) {
        display: block;
        text-align: left;
        padding: 0 10px;
        width: 50%;
        margin-left: 0;
      }
    }

    h3.h3-mobile {
      display: block;
      color: var(--Text-Text-Title, #07254A);
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px; /* 108.333% */
      margin-top: 20px;  
      padding: 0 10px;
      box-sizing: border-box;
      
  
      span {
        color: var(--Text-Accent, #005EB8);
      }

      @media only screen and (min-width: 992px) {
        display: none;
        text-align: left;
        padding: 0 10px;
        width: 50%;
        margin-left: 0;
      }
    }

    .mc-section-login-slider {
      .mc-slider-rotator {
        .slick-slider {
          .slick-list {
            @media only screen and (max-width: 992px) {
              width: 88%;
              overflow: visible;
            }
            .slick-track {
              .slick-slide {
                .mc-slider-rotator__slide {
                  .mc-cardbox-container {
                    padding: 16px;
                    .mc-cardbox-container__image {
                      span {
                        display: none !important;
                      }
                    }
                    h2 {
                      height: 76px;
                      div {
                        text-align: left;
                        padding-left: 24px;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        span {
                          color: var(--Text-Accent, #005EB8);
                        }
                      }
                      strong {
                        position: absolute;
                        left: 20px;
                        color: var(--Text-Accent, #005EB8);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                      }
                    }
                  }
                }
              }
            }
          }
          .slick-dots {
            text-align: center;

            @media only screen and (min-width: 992px) {
              text-align: left;
            }
          }
        }
      }
    }
  }
}

.mc-page.inner {
  h1 {
    color: var(--Text-Text-Title, #07254A);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 50px;

    span {
      color: var(--Text-Accent, #005EB8);
    }
  }
  h2 {
    color: var(--Text-Text-Title, #07254A);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &.mechanic {
    @media only screen and (max-width: 992px) {
      overflow: hidden;
    }
    .mc-section-login-slider {
      .mc-slider-rotator {
        .slick-slider {
          .slick-list {
            @media only screen and (max-width: 992px) {
              width: 88%;
              overflow: visible;
            }
            .slick-track {
              .slick-slide {
                .mc-slider-rotator__slide {
                  .mc-cardbox-container {
                    padding: 16px;
                    .mc-cardbox-container__image {
                      span {
                        display: none !important;
                      }
                    }
                    h2 {
                      height: 76px;
                      div {
                        text-align: left;
                        padding-left: 24px;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        span {
                          color: var(--Text-Accent, #005EB8);
                        }
                      }
                      strong {
                        position: absolute;
                        left: 20px;
                        color: var(--Text-Accent, #005EB8);
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                      }
                    }
                  }
                }
              }
            }
          }
          .slick-dots {
            text-align: center;
          }
        }
      }
    }
  }

  &.progress {
    .mc-section-card {
      border-radius: var(--size-md, 16px);
      border: 1px solid var(--Background-Background-Low, #F0F7FF);
      background: var(--Background-Background-Lowest, #FFF);
      box-shadow: 0px 209px 58px 0px rgba(51, 135, 209, 0.00), 0px 134px 53px 0px rgba(51, 135, 209, 0.02), 0px 75px 45px 0px rgba(51, 135, 209, 0.08), 0px 33px 33px 0px rgba(51, 135, 209, 0.13), 0px 8px 18px 0px rgba(51, 135, 209, 0.15);
      padding: 20px 14px;

      @media only screen and (min-width: 992px) {
        width: 830px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .mc-section-testForm {
      margin-top: 60px;
      margin-bottom: 40px;

      @media only screen and (min-width: 992px) {
        margin-bottom: 0px;
      }
    }
    .mc-section-card--last {
      border-radius: 8px;
      background: var(--Background-Background-Medium, #B9DCFE);
      box-shadow: 0px 209px 58px 0px rgba(51, 135, 209, 0.00), 0px 134px 53px 0px rgba(51, 135, 209, 0.02), 0px 75px 45px 0px rgba(51, 135, 209, 0.08), 0px 33px 33px 0px rgba(51, 135, 209, 0.13), 0px 8px 18px 0px rgba(51, 135, 209, 0.1);
      color: var(--Text-Text-Title, #07254A);
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 26px;
      padding: 12px 0px;
      max-width: 390px;
      margin-left: auto;
      margin-right: auto;

      @media only screen and (min-width: 992px) {
        font-size: 16px;
      }

    }
    .mc-section-card--copy {
      color: var(--Text-Text-Title, #07254A);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 30px;
      padding: 0 12px;
      z-index: 2;
    }
    .mc-section-card--cta {
      max-width: 390px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 30px;

      a {
        display: block;
        border-radius: 8px;
        background: var(--Button-Default, #005EB8);

        color: var(--Button-Invert, #F0F7FF);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-decoration: none;
        padding: 10px 0px;
      }
    }
  }

  &.awards {
    .mc-app-container {
      p {
        color: var(--Text-Text-Title, #07254A);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .mc-awards-container {
        width: 290px;
        margin: 0 auto;
        display: block;

        @media only screen and (min-width: 992px) {
          width: 930px;
        }

        .mc-awards-container__items {
          margin: 40px 0px;
          &--item {
            padding: 16px 12px;
            border-radius: var(--size-md, 16px);
            border: 1px solid var(--Outline-Low, #E0EEFE);
            background: var(--Background-Background-Lowest, #FFF);
            box-shadow: 0px 64px 18px 0px rgba(124, 192, 253, 0.00), 0px 41px 16px 0px rgba(124, 192, 253, 0.01), 0px 23px 14px 0px rgba(124, 192, 253, 0.05), 0px 10px 10px 0px rgba(124, 192, 253, 0.09), 0px 3px 6px 0px rgba(124, 192, 253, 0.10);
          
            .mc-awards-container__items--item__secondImage {
              display: none;
            }
          }
        }
      }
    }
  }

  &.major-award {
    .mc-major-award-t1 {
      background-color: #F0F7FF;
      padding: 20px 0px;

      @media only screen and (min-width: 992px) {
        background-color: transparent;
        height: 300px;
        margin-bottom: 40px;

        .mc-major-award-window {
          border-radius: var(--xl, 24px);
          background: var(--Background-Background-Low, #F0F7FF);
          box-shadow: 0px 209px 58px 0px rgba(51, 135, 209, 0.00), 0px 134px 53px 0px rgba(51, 135, 209, 0.02), 0px 75px 45px 0px rgba(51, 135, 209, 0.08), 0px 33px 33px 0px rgba(51, 135, 209, 0.13), 0px 8px 18px 0px rgba(51, 135, 209, 0.15);
        }
      }

      .mc-major-award-t1-left {
        text-align: center;

        @media only screen and (min-width: 992px) {
          text-align: left;
        }
        div {
          &:nth-child(1) {
            color: var(--Text-Text-Title, #07254A);
            text-align: left;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 26px;
            margin-bottom: 20px;
            padding-top: 14px;
          }
          &:nth-child(2) {
            color: var(--Text-Text-Title, #07254A);
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            margin-bottom: 20px;
          }

          span {
            color: var(--Text-Accent, #005EB8);
          }
        }

        @media only screen and (min-width: 992px) {
          width: 32%;
          height: 100%;
          display: inline-block;
          vertical-align: top;
          padding-left: 32px;
          padding-top: 64px;

          &.layout-2 {
            padding-top: 10px;
          }
          &.layout-3 {
            padding-top: 8px;
          }
          &.layout-4 {
            padding-top: 46px;
          }
        }
      }
      .mc-major-award-t1-right {
        img {
          width: 100%;
          height: auto;
        }

        @media only screen and (min-width: 992px) {
          width: 58%;
          height: 100%;
          display: inline-block;
          vertical-align: top;
          margin-left: 45px;
          padding-top: 20px;
          padding-bottom: 14px;
        }
      }
    }
    .mc-major-award-t2 {
      text-align: center;
      padding: 20px;
      width: 330px;
      margin: 20px auto;

      @media only screen and (min-width: 992px) {
        width: 900px;
        margin-bottom: 40px;
      }

      br {
        @media only screen and (max-width: 992px) {
          display: none;
        }
      }

      p {
        text-align: left;
        div {
          text-align: left !important;
          strong {
            color: var(--Text-Paragraph, #6D6D6D);
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }

        a {
          color: var(--Text-Accent, #005EB8);
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-decoration-line: underline;
        }
      }

      ul {
        margin-top: -8px;
        padding-left: 18px;
        li {
          text-align: left;
        }
      }

      div {
        &:nth-child(1) {
          color: var(--Text-Text-Title, #07254A);
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 26px; /* 108.333% */
          margin-bottom: 12px;
        }
        &:nth-child(2) {
          color: var(--Text-Paragraph, #6D6D6D);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .mc-major-award-t3 {
      background-color: #F0F7FF;
      padding: 20px 0px;

      @media only screen and (min-width: 992px) {
        padding: 30px 0px;
        
      }

      .mc-app-columns {
        @media only screen and (min-width: 992px) {
          display: flex;
          
        }

        .mc-app-columns__item {
          &:nth-child(1) {
            text-align: center;
            padding-top: 24px;
            div {
              color: var(--Text-Text-Title, #07254A);
              font-size: 32px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            ul {
              padding-left: 20px;
              text-align: left;
              li {
                color: var(--Text-Paragraph, #6D6D6D);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 16px;
              }
            }
            @media only screen and (min-width: 992px) {
              order: 2;
              padding-left: 30px;
              text-align: left;
              padding-top: 0px;
            }
          }
          &:nth-child(2) {
            @media only screen and (min-width: 992px) {
              order: 1;
            }
          }
        }
      }
    }
    .mc-major-award-t4 {
      text-align: center;
      padding: 20px;
      width: 330px;
      margin: 20px auto;

      @media only screen and (min-width: 992px) {
        width: 900px;
        margin-top: 50px;
        margin-bottom: 40px;
        
      }

      .mc-major-award-title2 {
        color: var(--Text-Text-Title, #07254A);
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 20px;
      }

      .mc-major-award-items {
        text-align: left;
        ul {
          text-align: left;
          padding-left: 18px;

          li {
            margin-bottom: 14px;
            color: var(--Text-Paragraph, #6D6D6D);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }

  &.terms {
    .center-text {
      text-align: center;
    }
    .mc-static-content {
      text-align: left;

      h2 {
        color: var(--Text-Text-Title, #07254A);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: left;
      }

      p {
        color: var(--Text-Paragraph, #6D6D6D);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  &.faqs {
    p {
      text-align: center;
    }
  }

  &.redemptions {
    @media only screen and (max-width: 992px) {
      overflow: hidden;
    }
    p {
      text-align: center;
    }
    .mc-awards-container {
      margin-top: 50px;
      margin-bottom: 70px;
      text-align: center;

      .mc-awards-container__items {
        .mc-awards-container__items--item {
          display: inline-block;
          margin: 0 10px;

          &:nth-child(1) {
            // display: block;
          }
        }
      }

      .mc-awards-container__items {
        margin: 40px 0px;
        &--item {
          padding: 16px 12px;
          border-radius: var(--size-md, 16px);
          border: 1px solid var(--Outline-Low, #E0EEFE);
          background: var(--Background-Background-Lowest, #FFF);
          box-shadow: 0px 64px 18px 0px rgba(124, 192, 253, 0.00), 0px 41px 16px 0px rgba(124, 192, 253, 0.01), 0px 23px 14px 0px rgba(124, 192, 253, 0.05), 0px 10px 10px 0px rgba(124, 192, 253, 0.09), 0px 3px 6px 0px rgba(124, 192, 253, 0.10);
        }
      }
    }
  }
}


.mc-major-award-banner {
  border-radius: 25px;
  border: 1px solid var(--Outline-Low, #E0EEFE);
  background: var(--Background-Background-Low, #F0F7FF);
  padding: 14px;
  box-sizing: border-box;
  margin: 50px 0px;
  padding-bottom: 30px;
  box-shadow: 0px 209px 58px 0px rgba(51, 135, 209, 0.00), 0px 134px 53px 0px rgba(51, 135, 209, 0.02), 0px 75px 45px 0px rgba(51, 135, 209, 0.08), 0px 33px 33px 0px rgba(51, 135, 209, 0.13), 0px 8px 18px 0px rgba(51, 135, 209, 0.15);

  @media only screen and (min-width: 992px) {
    padding-bottom: 14px;
  }
  img {
    width: 100%;
    height: auto;
  }

  h2 {
    color: var(--Text-Accent, #005EB8) !important;
    text-align: center !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;

    @media only screen and (min-width: 992px) {
      font-size: 24px !important;
      padding: 0 16px;
      text-align: left !important;
      padding-left: 26px !important;
      margin-top: 0px;
    }
  }
  p {
    color: var(--Text-Text-Title, #07254A) !important;
    text-align: center !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;

    span {
      color: var(--Text-Accent, #005EB8) !important;
    }

    @media only screen and (min-width: 992px) {
      text-align: left !important;
      padding-left: 26px !important;
    }

  }
  a {
    color: var(--Button-Default, #005EB8);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
    display: block;
    text-align: center;
    margin-top: 3 0px;
    margin: 0 auto;
    width: fit-content;
    border-radius: 8px;
    padding: 8px 12px;
    border: 1px solid transparent;

    @media only screen and (min-width: 992px) {
      display: block;
      width: 100%;
      text-align: left;
      padding-left: 60px;
    }

    &:hover{

      @media only screen and (max-width: 992px) {
        border: 1px solid var(--Outline-Low, #E0EEFE);
        background: var(--Background-Background-Medium, #B9DCFE);
        transition: all .3s linear;
        -webkit-transition: all .3s linear;
        -moz-transition: all .3s linear;
        -ms-transition: all .3s linear;
        -o-transition: all .3s linear;
      }
    }

  }
}

.noLoggedIn {
  .mc-header-container__nav {
    .mc-header-link {
      line-height: 62px;

      @media only screen and (min-width: 992px) {
        line-height: 52px;
      }
    }
  }

  .mc-header-container__submenu {
    .mc-app-container {
      opacity: 0 !important;
    }
  }
}

.global-loading {
  .mc-footer-container,
  .mc-header-container-page-login {
    opacity: 0 !important;
  }
}