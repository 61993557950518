.mc-counter-down {
  position: relative;
	background: var(--Button-Hover, #07254A);
	border: 1px solid gray;
	box-sizing: border-box;
	padding: 4px 10px;
  padding-bottom: 8px;
	text-align: center;

  .close-button {
    display: none;
    background-color: transparent;
    border: 0px;
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }

  &.position-bottom {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 5;

    .close-button {
      display: block;
    }

    @media only screen and (min-width: 992px) {
      padding: 28px 10px;
    }
    @media only screen and (max-width: 992px) {
      bottom: 56px;
      padding-bottom: 30px;
      padding-top: 18px;
    }
  }

  p {
    color: var(--color-FFFFFF, #FFF);
    text-align: center;
    font-size: var(--Size-Lg, 20px);
    font-style: normal;
    font-weight: 900;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 10px;

    @media only screen and (min-width: 992px) {
      display: inline-block;
      vertical-align: middle;
      width: 320px;
      margin-right: 14px;
      line-height: 24px;
      position: relative;
      top: 6px;
    }
  }

  ul {
    padding: 0px;
    margin: 0px auto;
    max-width: 400px;

    @media only screen and (min-width: 992px) {
      display: inline-block;
      vertical-align: middle;
      width: 320px;
      margin-left: 14px;
    }

    li {
      display: inline-block;
      vertical-align: middle;
      width: 25%;
      color: var(--color-FFFFFF, #FFF);
      text-align: center;
      font-size: var(--Size-Xs, 12px);
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      position: relative;

      &:after {
        content: ':';
        position: absolute;
        right: -2px;
        z-index: 1;
        color: var(--color-D6EDFC, #D6EDFC);
        text-align: center;
        font-size: var(--Size-Lg, 20px);
        font-style: normal;
        font-weight: 900;
        line-height: 40px;
        top: 0px;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      span {
        display: block;
        font-weight: bold;
        color: var(--color-D6EDFC, #D6EDFC);
        text-align: center;
        font-size: var(--Size-XL, 24px);
        font-style: normal;
        font-weight: 900;
        line-height: 28px;
      }
    }
  }
}