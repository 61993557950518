.mc-tabs-container {
  position: relative;

  &--head {
    position: relative;
    height: 46px;
    border-radius: var(--size-md, 16px);
    border-bottom: 2px solid var(--Outline-High, #005EB8);
    background: var(--Background-Background-Lowest, #FFF);
    box-shadow: 0px 12px 3px 0px rgba(41, 50, 122, 0.00), 0px 7px 3px 0px rgba(41, 50, 122, 0.01), 0px 4px 3px 0px rgba(41, 50, 122, 0.05), 0px 2px 2px 0px rgba(41, 50, 122, 0.09), 0px 0px 1px 0px rgba(41, 50, 122, 0.10);
    margin-bottom: 22px;

    @media only screen and (min-width: 992px) {
      width: 500px;
      margin-left: auto;
      margin-right: auto;
    }

    &__tab {
      float: left;
      height: 100%;
      width: 50%;
      text-align: center;
      border: 0px;
      background-color: transparent;
      cursor: pointer;

      color: var(--Button-Default, #005EB8);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    span {
      position: absolute;
      left: 2%;
      width: 45%;
      height: 3px;
      z-index: 1;
      background-color: #005EB8;
      bottom: 0px;

      -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
      -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
      -o-transition: all .3s cubic-bezier(.77,0,.175,1);
      transition: all .3s cubic-bezier(.77,0,.175,1);
    }
  }

  &--content {
    position: relative;

    &__body {
      position: relative;
      display: none;

      &--title {
        color: var(--Text-Text-Title, #07254A);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 28px 0px;
      }

      &--boxes {
        position: relative;
        height: 72px;
        margin-bottom: 34px;
      }
      &--box {
        position: relative;
        float: left;
        height: 100%;
        width: 48.4%;
        border-radius: 12px;
        box-sizing: border-box;
        padding: 12px 16px;
        background: var(--Background-Background-Medium, #B9DCFE);
        box-shadow: 0px 64px 18px 0px rgba(124, 192, 253, 0.00), 0px 41px 16px 0px rgba(124, 192, 253, 0.01), 0px 23px 14px 0px rgba(124, 192, 253, 0.05), 0px 10px 10px 0px rgba(124, 192, 253, 0.09), 0px 3px 6px 0px rgba(124, 192, 253, 0.10);

        span {
          color: #12325E;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        div {
          color: #12325E;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }

        &:nth-child(2) {
          float: right;
        }
      }

      &--progress {
        border-radius: var(--size-md, 16px);
        background: var(--Gradient, linear-gradient(180deg, #0575E6 0%, #023B82 132.61%));
        height: 34px;
        margin-left: 20px;
        margin-right: 20px;

        .mc-progressBar-container {
          height: 4px;
          margin: 0px;
          margin-left: 28px;
          margin-right: 80px;
          top: 15px;
          border: 0px;
          background-color: #fff;
          border-radius: 10px;

          .mc-progressBar-percent {
            text-align: left;
            width: 70px;
            right: -72px;
            top: -12px;
            left: auto;
            color: var(--Background-Background-Lowest, #FFF);
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
          }

          .mc-progressBar-progress {
            background-color: #fff;
          }

          svg {
            position: absolute;
            top: -14px;
            transform: translate(-15px, 1px);
          }
        }
      }

      &--cats {
        margin: 30px 0px;

        &__items {
          position: relative;
          margin-top: 40px;

          @media only screen and (max-width: 992px) {
            height: 300px;
            overflow: auto;
          }

          @media only screen and (min-width: 992px) {
            margin-bottom: 62px;
          }

          &--item {
            display: inline-block;
            vertical-align: top;
            width: 33%;
            height: 70px;
            text-align: center;
            margin-bottom: 20px;

            @media only screen and (min-width: 992px) {
              width: 16%;
            }

            div {
              width: 40px;
              height: 40px;
              border-radius: 50px;
              border: 1px solid var(--Outline-High, #005EB8);
              margin: 0 auto;
              margin-top: 6px;
              position: relative;

              div {
                position: absolute;
                left: 0px;
                top: 0px;
                height: 100%;
                height: 100%;
                z-index: 1;
                background-color: transparent;
                margin: 0;
                border: 0px;

                svg {
                  transform: scale(1.15);
                  position: relative;
                  top: 10px;

                  path {
                    fill: #000;
                  }
                }
              }

              img {
                width: 12px;
                height: 12px;
                position: absolute;
                top: 0px;
                right: -2px;
                z-index: 2;
              }
            }

            span {
              color: var(--Text-Text-Title, #07254A);
              text-align: center;
              font-size: 10px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              display: block;
              margin-top: 8px;
            }

            &.active {
              div {
                background: var(--Gradient, linear-gradient(180deg, #0575E6 0%, #023B82 132.61%));
                svg {
                  top: 8px;

                  path {
                    fill: #fff
                  }
                }
              }
              span {
                font-weight: 800;
              }
            }
          }
        }

        p {
          color: var(--Text-Text-Title, #07254A);
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          span{
            color: #005EB8;
            font-size: 18px;
          }
        }

        @media only screen and (min-width: 992px) {
          width: 600px;
          margin-left: auto;
          margin-right: auto;

          p {
            width: 430px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }

      @media only screen and (min-width: 992px) {
        box-sizing: border-box;
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 30px;
      }

      .mc-app-columns {
        .mc-app-columns__item {
          &:nth-child(1) {
            @media only screen and (min-width: 992px) {
              .mc-app-rely {
                height: 76%;
                width: 90%;
                position: relative;
                top: -10px;
              }
            }
          }
          &:nth-child(2) {
            @media only screen and (max-width: 992px) {
              display: none !important;
            }
          }
        }
      }

    }
  }

  &.current-0 {
    .mc-tabs-container--head {
      .mc-tabs-container--head__tab {
        &:nth-child(1) {
          font-weight: bold !important;
        }
      }
    }
    .mc-tabs-container--content {
      .mc-tabs-container--content__body {
        &:nth-child(1) {
          display: block;
        }
      }
    }
  }
  &.current-1 {
    .mc-tabs-container--head {
      .mc-tabs-container--head__tab {
        &:nth-child(2) {
          font-weight: bold !important;
        }
      }

      span {
        left: 53%;
      }
    }
    .mc-tabs-container--content {
      .mc-tabs-container--content__body {
        &:nth-child(2) {
          display: block;

          img {
            display: block;
            margin: 24px auto;
          }

          p {
            color: var(--Color-Black-1, #0F0F0F);
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 30px;

            @media only screen and (max-width: 992px) {
              br {
                display: none !important;
              }
            }
          }

          a {
            color: var(--Button-Default, #005EB8);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-decoration-line: underline;
            display: block;
            text-align: center;
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}