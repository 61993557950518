.mc-profile-card {
  position: relative;
  height: 32px;

  .mc-profile-avatar__letters {
    width: 32px;
    height: 32px;
    position: absolute;
    left: 0px;
    top: 0px;
    border-radius: 80px;
    background-color: #231524;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    line-height: 30px;
    z-index: 2;
  }

  .mc-profile-name {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    padding-left: 0px;
    line-height: 30px;
    padding-left: 40px;
  }
}