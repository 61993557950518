.mc-faqs-container {
  margin: 28px 0px;

  .mc-accordeon-container {
    position: relative;
    margin: 14px auto;
    max-width: 860px;
    border-bottom: 1px solid var(--Outline-Medium, #36A1FA);

    &.active {
  
      .mc-accordeon-toggle-icon {
        transform: rotate(-180deg) !important;
        top: 13px !important;
      }
    }
  
    .mc-accordeon-toggle {
      display: block;
      position: relative;
      cursor: pointer;
      padding: 16px 14px;
      padding-right: 58px;
    
      color: var(--color-primario-005EB8-Base, #005EB8);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      box-sizing: border-box;
  
      &:hover{
        opacity: 0.8;
      }
    }
    
    .mc-accordeon-toggle-icon {
      position: absolute;
      width: 22px;
      height: 22px;
      right: 16px;
      top: 17px;
      -webkit-transition: all .2s cubic-bezier(.77,0,.175,1);
      -moz-transition: all .2s cubic-bezier(.77,0,.175,1);
      -o-transition: all .2s cubic-bezier(.77,0,.175,1);
      transition: all .2s cubic-bezier(.77,0,.175,1);
    
      svg {
        width: 100%;
        height: auto;
        transform: scale(0.7);

        path {
          stroke: $primaryColorDark;
        }
      }
    }
    
    .mc-accordeon-content {
      padding-bottom: 22px;
      padding-top: 0px;
      padding-right: 45px;
      padding-left: 16px;
      text-align: justify;
      color: var(--Text-Paragraph, #6D6D6D);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &.mc-faqs-container-type-1 {

  }
  &.mc-faqs-container-type-2 {

  }
}