.SectionAllies {
  margin: 50px auto;
  padding: 0 0px;

  .no-vertical {
    // vertical-align: text-top !important;
  }

  h2.title-allies {
    color: var(--Text-Text-Title, #07254A) !important;
    font-size: 40px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important;
    text-align: left !important;

    strong {
      color: var(--Text-Accent, #005EB8);
      font-weight: 700;
    }

    @media (max-width: 992px) {
      text-align: center !important;
      font-size: 24px !important;
      font-style: normal !important;
      line-height: 26px !important;
    }
  }

  @media (max-width: 768px) {
    padding: 0 0px;

  }

  .slick-dots li button:before {
    font-family: "slick";
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    content: "";
    text-align: center;
    color: #B9DCFE;
    background: #B9DCFE;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-dots li.slick-active button:before {
    content: '';
    width: 20px;
    height: 8px;
    border-radius: 20px;
    background: #005EB8;
  }

  .slider-allies{
    width: 550px;
    margin: 0 auto;
    @media (max-width: 992px) {
      width: 600px;
     
    }
    @media (max-width: 768px) {
      width: 500px;
     
    }
    @media (max-width: 576px) {
      width: 400px;
     
    }
    @media (max-width: 490px) {
      width: 360px;
     
    }
    @media (max-width: 360px) {
      width: 300px;
     
    }
  }
}