.mc-goal-counter {
	position: relative;
	box-sizing: border-box;
	padding: 20px;
	text-align: center;

	color: var(--Text-Text-Title, #07254A);
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: 26px;
    width: 210px;
    margin: 0 auto;
	
	span {
		color: var(--Text-Accent, #005EB8);
	}

	@media only screen and (min-width: 992px) {
		width: 100%;
	}
}