.mc-progressCircle-container {
  
  &__progress {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    margin: 0 auto;
    text-align: center;
    font-size: 38px;
    font-weight: bold;
    box-sizing: border-box;
    position: relative;

    span {
      display: block;
      width: 60%;
      margin: 0 auto;
      font-size: 16px;
      font-weight: 600;
      z-index: 2;
    }

    &--overlay {
      position: absolute;
      z-index: 1;
      background: rgb(223, 223, 223);
      left: 19px;
      top: 19px;
      right: 19px;
      bottom: 19px;
      border-radius: 600px;

      > span {
        position: absolute;
        left: 0px;
        top: -19px;
        right: 0px;
        margin: auto;
        background-color: #fff;
        border-radius: 100px;
        width: 19px;
        height: 19px;
        transform: scale(1.35);
        -webkit-box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.2);
        box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.2);

        .mc-icon {
          width: 100%;
          height: 100%;

          & > span {
            width: 100%;
            height: 100%;
            text-align: center;

            svg {
              width: 100%;
              height: 100%;
              transform: scale(0.75);

              path {
                stroke: $primaryColor;
              }
            }
          }
        }
      }
    }
  }
}