.mc-cardbox-container {
  position: relative;
  background-color: rgb(245, 245, 245);
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;
  margin: 12px;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.15);

  & > h2 {
    font-size: 20px;
    color: $primaryColor;
    text-align: center;
  }

  & > h4 {
    display: none;
  }

  & > div {
    font-size: 15px;
    color: $colorText;
    text-align: center;
  }

  &__image {
    position: relative;
    span {
      border-radius: 8px;
      background: var(--Background-Background-Low, #F0F7FF);
      position: absolute;
      left: 10px;
      bottom: 10px;
      z-index: 2;

      color: var(--Text-Accent, #005EB8);
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 4px 10px;
      padding-top: 8px;
    }
    img {
      width: 100px;
      height: auto;
      margin: 0 auto;
      display: block;
    }
  }

  &.cardBoxStyle-1 {
    border-radius: 16px;
    background: var(--Background-Background-Medium, #B9DCFE);
    box-shadow: 0px 64px 18px 0px rgba(124, 192, 253, 0.00), 0px 41px 16px 0px rgba(124, 192, 253, 0.01), 0px 23px 14px 0px rgba(124, 192, 253, 0.05), 0px 10px 10px 0px rgba(124, 192, 253, 0.09), 0px 3px 6px 0px rgba(124, 192, 253, 0.10);
    padding: 12px;
    margin-bottom: 26px;
    img {
      width: 100%;
      height: auto;
    }
    h2 {
      color: var(--Text-Text-Title, #07254A);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      span{
        color: #005EB8;
      }
    }
  }
  &.cardBoxStyle-2 {
    border-radius: 16px;
    border: 1px solid var(--Outline-Low, #E0EEFE);
    background: var(--Background-Background-Lowest, #FFF);
    box-shadow: 0px 64px 18px 0px rgba(124, 192, 253, 0.00), 0px 41px 16px 0px rgba(124, 192, 253, 0.01), 0px 23px 14px 0px rgba(124, 192, 253, 0.05), 0px 10px 10px 0px rgba(124, 192, 253, 0.09), 0px 3px 6px 0px rgba(124, 192, 253, 0.10);
    padding: 16px;
    padding-bottom: 12px;
    margin-bottom: 30px;

    img {
      width: 100%;
      height: auto;
    }

    h2 {
      color: var(--Text-Paragraph, #6D6D6D);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-align: left;
      margin: 10px 0px;
    }
  }
}