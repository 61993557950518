.mc-button-container {
  display: block;
  width: 100%;
  text-align: center;

  button {
    display: block;
    width: 100%;
    border: 0px;
    height: 45px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
    cursor: pointer;
    border-radius: 8px;

    .inline-loader {
      width: 30px;
      height: auto;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__primary {
    button {
      background-color: $primaryColor;
      color: #fff;

      &:hover {
        background-color: $primaryColorHover;
      }
      &.mc-button-container__status--inactive {
        background-color: #B0B0B0 !important;
        cursor: no-drop;
        opacity: 0.7;
        color: #333333 !important;
        font-weight: 600 !important;
      }
    }
  }

  &__secondary {
    button {
      background-color: #fff;
      color: $primaryColor;;
      border: 1px solid $primaryColor;

      &:hover {
        background-color: $primaryColorLight;
      }
      &.mc-button-container__status--inactive {
        background-color: $secondaryColorDisable;
        cursor: no-drop;
        opacity: 0.7;
      }
    }
  }
  &.pushModalBTN{
    button{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

    }
  }
}