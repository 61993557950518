// Base
@import "./base/fonts";
@import "./base/variables";
@import "./base/normalize";

// Components
@import "./components/button";
@import "./components/awards";
@import "./components/allies";
@import "./components/profile";
@import "./components/modals";
@import "./components/loginForm";
@import "./components/accordeon";
@import "./components/progressBar";
@import "./components/progressCircle";
@import "./components/sliderRotator";
@import "./components/cardBox";
@import "./components/textImageBox";
@import "./components/banner";
@import "./components/alertMessage";
@import "./components/dashboard";
@import "./components/preloader";
@import "./components/goalCounter";
@import "./components/counterDown";
@import "./components/testQuetions";
@import "./components/tabs";
@import "./components/benefits";
@import "./components/tour";
@import "./components/sectionAllies";
@import "./components/microBanner";
@import "./components/preheader";

// Layout
@import "./layout/container";
@import "./layout/columns";
@import "./layout/header";
@import "./layout/footer";

// Pages
@import "./pages/general";

// general
.mc-app-fullheight {
  height: 100%;
}

.mc-app-rely {
  position: relative;
  display: table;
  height: 100%;
  width: 100%;
  z-index: 2;

  .mc-app-centry {
    position: relative;
    vertical-align: middle;
    display: table-cell;
    z-index: 1;
  }
}

.slow_ani {
  -webkit-transition: all .3s cubic-bezier(.77,0,.175,1);
  -moz-transition: all .3s cubic-bezier(.77,0,.175,1);
  -o-transition: all .3s cubic-bezier(.77,0,.175,1);
  transition: all .3s cubic-bezier(.77,0,.175,1);
}


.App {
  @media only screen and (min-width: 992px) {
    overflow: hidden;
  }
  &.modalOpens {
    height: 750px;
    .mc-awards-container__steper {
      .slick-slider {
        .slick-track,
        .slick-list {
          position: fixed !important;
          left: 0px !important;
          top: 0px !important;
          right: 0px !important;
          bottom: 0px !important;
          width: auto !important;
          height: auto !important;
          z-index: 999;

          .mc-awards-container__items {
            .mc-awards-container__items--item {
              background-color: transparent !important;

              .mc-custom-modal__overlay {
                background-color: rgba(83, 83, 83, 1);
              }
              .mc-awards-container__items--item__image,
              .mc-awards-container__items--item__info {
                visibility: hidden !important;
              }
            }
          }
        }
      }
    }
    .mc-header-container {
      opacity: 0;
    }
    .mc-footer-container {
      display: none !important;
    }
  }

  &.noLoggedIn {
    .mc-header-container-type-1 {
      .mc-header-container__nav {
        @media only screen and (min-width: 992px) {
          right: 10px;
        }
      }
    }

  }

  .mc-section {
    display: block;
    margin: 40px 0px;
  }

  .custom-forms {
    
  }
}

// Pages
.demo-section {
  margin: 20px 0px;
  display: block;
}

.extra-text-modal {
  text-align: center;
  font-size: 12px;
}

.isSupermarket {
  .mc-custom-modal__center--window {
    .mc-custom-modal__center--window__actions {
      display: none !important;
    }
    .extra-text-modal {
      margin-bottom: 30px;
    }
  }
}